80%<template>
    <div style="height: 100%; overflow: hidden;">
        <a-row :gutter="8">
            <a-col :lg="{span: 2}" :xs="{span: 4}">
                <a-select
                    ref="select"
                    v-model:value="now_type"
                    style="width: 100%;"
                    @change="handleTypeChange"
                    >
                    <a-select-option value="waitlist">{{ $t('waitlist.waitlist') }}</a-select-option>
                    <a-select-option value="callahead">{{ $t('waitlist.callahead') }}</a-select-option>
                </a-select>
            </a-col>
            <a-col :lg="{span: 1}" :xs="{span: 1}" v-if="now_type == 'waitlist'">
                <a-button aria-disabled="true" :disabled="true"> <HistoryOutlined :style="{fontSize: '18px'}"/> </a-button>
            </a-col>
            <a-col :span="6" v-if="!is_mobile && now_type == 'waitlist'">
                <a-input-search
                    v-model:value="q"
                    :placeholder="$t('common.search')"
                    enter-button
                    @search="onSearch"
                    style="padding-left: 6px;"
                />
            </a-col>
            <a-col :lg="{span: 10}" :xs="{span: 12}" style="text-align: center;" v-if="now_type == 'waitlist'">
                <a-pagination simple @change="get_list" v-model:current="current" :page-size="page_size" :total="total_count" :show-size-changer="false" show-less-items />
            </a-col>
            <a-col :span="5" :offset="now_type == 'waitlist' ? 0 : (is_mobile ? 13 : 17)" style="text-align: right;">
                <a-space>
                    <!-- <a-spin v-if="is_loading"/>
                    <span v-else style="width: 20px;display: inline-block;">&nbsp;</span> -->
                    <a-button aria-disabled="true" @click="onSearch">
                        <Loading3QuartersOutlined v-if="!is_loading" :style="{fontSize: '18px'}"/>
                        <LoadingOutlined v-else :style="{fontSize: '18px'}"/>
                    </a-button>
                    <a-button v-if="false"> <GlobalOutlined :style="{fontSize: '18px'}"/> </a-button>
                    <a-button @click="doShowLayer('show_add_layer')"> <UsergroupAddOutlined :style="{fontSize: '18px'}"/> </a-button>
                </a-space>
            </a-col>
        </a-row>
        <a-divider />
        <div v-if="false">
            <a-skeleton-input style="width: 25%" :active="true" size="large" :block="true"/>
            <a-skeleton-input style="width: 25%" :active="true" size="large" :block="true"/>
            <a-skeleton-input style="width: 25%" :active="true" size="large" :block="true"/>
            <a-skeleton-input style="width: 25%" :active="true" size="large" :block="true"/>
        </div>
        <div v-if="now_type == 'waitlist'">
            <a-flex gap="small" class="party_type_zone">
                <div class="paty_type_item" @click="choosePartyTypeTab(item.value)" v-for="(item) in party_type_list" :key="item.value" :style="{ ...baseStyle, background:  item.value == current_party_type ? '#fff' : '#c9d0d9bf'}">
                    <h4>{{ item.name }}</h4>
                    <h4>{{ party_type_data_dict[item.value] && party_type_data_dict[item.value].count || 0 }}({{ party_type_data_dict[item.value] && party_type_data_dict[item.value].people_count || 0 }})</h4>
                </div>
            </a-flex>
            <a-list
                class="demo-loadmore-list"
                item-layout="horizontal"
                :data-source="list"
                style="margin-top: 10px; overflow: auto; height: 80%;"
                :style="{height: is_mobile ? '70vh' : '75vh'}"
            >
                <template #renderItem="{ item }">
                    <a-list-item :style="{backgroundColor: item.color_tags && item.color_tags.length > 0 ? item.color_tags[0].color : '#fff'}" style="margin-top: 7px; height: 100px;">
                        <template #actions>
                        </template>
                        <a-list-item-meta @click="doShowInfoLayer(item)">
                            <template #title>
                                <span>{{ item.customer_name + '(' + item.people_num + ')' }}</span>
                                <a-tag v-if="false" color="#87d068">16 VISITS</a-tag>
                                <div> {{ item.wait_code + ' | ' + item.phone_num_short }}</div>
                                <div style="white-space: nowrap; overflow: hidden;">
                                    <span v-if="item.note">
                                        <AlertFilled />
                                        <span style="text-decoration: underline;">{{ item.note }}</span>
                                    </span>
                                    <span v-if="item.options" style="margin-left: 10px;">
                                        <BorderlessTableOutlined />
                                        {{ item.options }}
                                    </span>
                                </div>
                                <div v-if="item.missed == 1" style="color: red;">
                                    <FieldTimeOutlined />
                                    {{ moment.tz(item.missed_timestamp * 1, timezone).format('HH:mm A') }}
                                </div>
                            </template>
                            <template #avatar>
                                <div class="custom_big_btn" :class="{yellow_color2: item.table_ready_sent == 1}" style="padding-top: 10px;">
                                    <h4>{{ item.wait_from_now }}</h4>
                                    <h4 v-if="item.missed == 0 && item.pre_tstatus < 2">{{ $t('waitlist.waiting') }}</h4>
                                    <h4 v-if="item.pre_tstatus == 6">{{ $t('waitlist.checked_in') }}</h4>
                                    <h4 v-if="item.missed == 1">{{ $t('waitlist.no_show') }}</h4>
                                </div>
                            </template>
                        </a-list-item-meta>
                        <div v-if="item.missed == 0">
                            <div class="custom_big_btn" :class="{yellow_color2: item.pre_tstatus == 6}" @click="doCheckIn(item)">
                                <SafetyCertificateOutlined class="list_icon"/>
                                <h5 style=" margin-top: 5px;">{{ item.pre_tstatus == 6 ? $t('waitlist.checked_in') : $t('waitlist.check_in')}}</h5>
                            </div>
                            <div class="custom_big_btn" style="margin-left: 10px;" @click="doShowNotifyLayer(item)">
                                <MailOutlined class="list_icon"/>
                                <h5 style="color: #000; margin-top: 5px;" v-if="item.table_ready_sent == 0">{{ $t('waitlist.notify') }}</h5>
                                <h5 style="color: #000; margin-top: 5px;" v-if="item.table_ready_sent == 1">
                                    <MessageFilled />
                                    {{ item.remind_count + ' · ' + item.ready_text_time }}
                                </h5>
                            </div>
                            <div class="custom_big_btn" style="margin-left: 10px;" @click="doShowSeatLayer(item)">
                                <CoffeeOutlined class="list_icon"/>
                                <h5 style="color: #000; margin-top: 5px;">{{ $t('waitlist.seat') }}</h5>
                            </div>
                        </div>
                        <div v-else>
                            <div class="custom_big_btn" style="color: rgb(233, 60, 60); border-color: rgb(233, 60, 60);" @click="doRestore(item)">
                                <RedoOutlined style="font-size: 38px;"/>
                                <h5 style="margin-top: 5px;">{{ $t('waitlist.restore') }}</h5>
                            </div>
                            <div class="custom_big_btn" style="margin-left: 10px;" @click="doShowSeatLayer(item)">
                                <CoffeeOutlined style="font-size: 38px; color: #000;"/>
                                <h5 style="color: #000; margin-top: 5px;">{{ $t('waitlist.seat') }}</h5>
                            </div>
                        </div>
                    </a-list-item>
                </template>
            </a-list>
        </div>
        <div v-if="now_type == 'callahead'">
            <a-list
                class="demo-loadmore-list"
                item-layout="horizontal"
                :data-source="callahead_list"
                style="margin-top: 10px; overflow: auto; height: 80%;"
                :style="{height: is_mobile ? '75%' : '80%'}"
            >
                <template #renderItem="{ item }">
                    <a-list-item style="margin-top: 7px; height: 100px; backgroundColor: #fff;">
                        <a-list-item-meta @click="doShowCallaheadInfoLayer(item)">
                            <template #title>
                                <span>{{ item.customer_name + '(' + item.people_num + ')' }}</span>
                                <div> {{ item.phone_num }}</div>
                                <div style="white-space: nowrap; overflow: hidden;">
                                    <span v-if="item.note">
                                        <AlertFilled />
                                        <span style="text-decoration: underline;">{{ item.note }}</span>
                                    </span>
                                    <span v-if="item.options" style="margin-left: 10px;">
                                        <BorderlessTableOutlined />
                                        {{ item.options }}
                                    </span>
                                </div>
                            </template>
                            <template #avatar>
                                <div class="custom_big_btn" style="padding-top: 10px;">
                                    <h4>{{ item.preorder_time }}</h4>
                                    <h4>{{ $t('waitlist.callahead') }}</h4>
                                </div>
                            </template>
                        </a-list-item-meta>
                        <div>
                            <div class="custom_big_btn" style="margin-left: 10px;" @click="doDelCallahead(item)">
                                <RestOutlined class="list_icon"/>
                                <h5 style="color: #000; margin-top: 5px;">{{ $t('common.delete') }}</h5>
                            </div>
                            <div class="custom_big_btn" style="margin-left: 10px;" @click="doJoinCallahead(item)">
                                <PlusCircleOutlined class="list_icon"/>
                                <h5 style="color: #000; margin-top: 5px;">{{ $t('waitlist.join_waitlist') }}</h5>
                            </div>
                        </div>
                    </a-list-item>
                </template>
            </a-list>
        </div>
        <a-drawer :width="!is_mobile ? 430 :  375" :title="$t('waitlist.add_waitlist')" placement="right" :open="show_add_layer" @close="onClose">
            <template #extra>
                <a-button type="primary" @click="doSubmitJoinWaitlist" :loading="is_adding">{{ $t('common.submit' )}}</a-button>
            </template>
            <a-radio-group v-model:value="add_type" name="radioGroup">
                <a-radio value="waitlist">{{ $t('waitlist.now_waitlist') }}</a-radio>
                <a-radio value="callahead">{{ $t('waitlist.callahead') }}</a-radio>
            </a-radio-group>
            <h3 style="text-align: center; margin-top: 20px;">
                {{ $t('waitlist.guest_number') }}
                <DownOutlined v-if="!show_guest_number_zone" @click="showGuestNumberZone"/>
            </h3>
            <div>
                <a-flex style="margin-bottom: 10px;" wrap="wrap" gap="small" v-if="show_guest_number_zone">
                    <div @click="choosePeopleNum(index + 1)" class="guest_num_item" v-for="(item, index) in new Array(15)" :key="item">
                        <span class="active" v-if="add_people_num == (index + 1)">{{ index + 1 }}</span>
                        <span v-else>{{ index + 1 }}</span>
                    </div>
                </a-flex>
                <span style="width: 30%; display: inline-block;">{{ $t('waitlist.guest_number') }} *</span>
                <a-input type="number" style="display: inline-block; width: auto;" v-model:value="add_people_num" :placeholder="$t('waitlist.guest_number')" />
            </div>
            <div style="margin-top: 10px;">
                <span style="width: 30%; display: inline-block;">{{ $t('waitlist.guest_name') }} *</span>
                <a-input style="display: inline-block; width: auto;" v-model:value="add_customer_name" :placeholder="$t('waitlist.guest_name')" />
            </div>
            <div style="margin-top: 10px;" v-if="add_type == 'callahead'">
                <span style="width: 30%; display: inline-block;">{{ $t('waitlist.join_time') }} *</span>
                <a-date-picker :disabled-date="disabledDate" :inputReadOnly="true" show-time format="YYYY/MM/DD HH:mm" @change="onDateChange" v-model:value="value1" />
            </div>
            <div style="margin-top: 10px;">
                <span style="width: 30%; display: inline-block;">{{ $t('waitlist.phone_num') }} </span>
                <a-input-group compact style="display: inline-block; width: auto;">
                    <a-select v-model:value="add_phone_code">
                        <a-select-option value="+1"> +1 </a-select-option>
                        <a-select-option value="+65"> +65 </a-select-option>
                        <a-select-option value="+86"> +86 </a-select-option>
                    </a-select>
                    <a-input v-model:value="add_phone_num" style="width: 50%" />
                </a-input-group>
            </div>
            <div style="margin-top: 10px;">
                <span>{{ $t('waitlist.visit_note') }} </span>
                <a-textarea v-model:value="add_note" :placeholder="$t('waitlist.visit_note')" :rows="4" />
            </div>
        </a-drawer>
        <a-modal v-model:open="show_notify_layer" :zIndex="2000" :width="400" :title="layer_user_info.wait_code + ' | ' + layer_user_info.phone_num_short + ' | ' + layer_user_info.customer_name" :footer="null">
            <div style="position: relative;">
                <a-divider orientation="left">{{ $t('waitlist.onsite_notify') }}</a-divider>
                <div class="custom_big_btn" :class="{yellow_color2: layer_user_info.is_table_ready == 1}" style="margin-left: 25px;" @click="do_send_notification('broadcast')">
                    <DesktopOutlined style="font-size: 38px; color: #000;"/>
                    <h5 style="color: #000; margin-top: 5px;">{{ $t('waitlist.broadcast') }}</h5>
                </div>
                <a-divider orientation="left">{{ $t('waitlist.table_ready') }}</a-divider>
                <div class="custom_big_btn" style="margin-left: 25px;" @click="do_send_notification('two_way_message')">
                    <CommentOutlined style="font-size: 38px; color: #000;"/>
                    <h5 style="color: #000; margin-top: 5px;">{{ $t('waitlist.two_way_sms') }}</h5>
                </div>
                <div class="custom_big_btn" style="margin-left: 25px;" @click="do_send_notification('two_way_voice')">
                    <PhoneOutlined style="font-size: 38px; color: #000;"/>
                    <h5 style="color: #000; margin-top: 5px;">{{ $t('waitlist.two_way_voice') }}</h5>
                </div>
                <a-divider orientation="left">{{ $t('common.other') }}</a-divider>
                <div class="custom_big_btn" style="margin-left: 25px;" @click="do_send_notification('resend_invite')">
                    <SendOutlined style="font-size: 38px; color: #000;"/>
                    <h5 style="color: #000; margin-top: 5px;">{{ $t('waitlist.resend_invite') }}</h5>
                </div>
                <a-spin v-if="is_sending" size="large" style="position: absolute; top: 50%; left: 50%;">
                </a-spin>
                <div v-if="is_sending" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; background-color: #eee; opacity: 0.5;"></div>
            </div>
        </a-modal>
        <a-modal v-model:open="show_seat_layer" :zIndex="2000" :title="layer_user_info.wait_code + ' | ' + layer_user_info.phone_num_short" width="300px" :footer="null">
            <h2 style="text-align: center; margin-top: 30px;">{{ $t('waitlist.seat') }} {{ layer_user_info.customer_name }}({{ layer_user_info.people_num }})?</h2>
            <a-input style="width: 250px; margin-top: 20px;" v-model:value="seat_table_name" :placeholder="$t('common.table')" />
            <div>
                <a-button @click="do_seat" style="width: 250px; display: inline-block; margin-top: 20px; background-color: #34af48; color: #fff;">{{ $t('waitlist.seat') }}</a-button>
            </div>
            <div>
                <a-button :disabled="true" style="width: 250px; display: inline-block; margin-top: 20px;" type="primary">{{ $t('waitlist.seat_with_table') }}</a-button>
            </div>
        </a-modal>
        <a-drawer class="user_info_zone" :zIndex="1000" @close="doHideInfoLayer" v-model:open="show_info_layer" placement="right" :width="!is_mobile ? 400 : 375" :footer="null">
            <a-skeleton v-if="false" active />
            <div class="user_info_header">
                <a-row>
                    <a-col :span="8">
                        <div class="custom_big_btn yellow_color1" style="padding-top: 10px;">
                            <h3>{{ layer_user_info.wait_from_now }}</h3>
                            <h4>{{ $t('waitlist.waiting') }}</h4>
                        </div>
                    </a-col>
                    <a-col :span="14">
                        <div>
                            <h4>{{ layer_user_info.people_num }} | {{ layer_user_info.customer_name }}</h4>
                            <p style="margin-bottom: 5px;">{{ layer_user_info.phone_num }}</p>
                            <span v-for="item in layer_user_info.guest_tags" v-bind:key="item.name">
                                <a-tag :color="item.color">{{ item.name }}</a-tag>
                            </span>
                        </div>
                    </a-col>
                    <a-col v-if="false">
                        <EditOutlined style="font-size: 24px; color: #000;"/>
                    </a-col>
                </a-row>
            </div>
            <a-divider />
            <div class="user_top_action">
                <a-space>
                    <div class="custom_big_btn yellow_color1" @click="doShowNotifyLayer(layer_user_info)">
                        <MailOutlined style="font-size: 28px; color: #fff;"/>
                        <h5 style="margin-top: 5px;" v-if="layer_user_info.table_ready_sent == 0">{{ $t('waitlist.notify') }}</h5>
                        <h5 style="margin-top: 5px;" v-if="layer_user_info.table_ready_sent == 1">
                            <MessageFilled />
                            {{ layer_user_info.remind_count + ' · ' + layer_user_info.ready_text_time }}
                        </h5>
                    </div>
                    <div class="custom_big_btn yellow_color2" @click="doCheckIn(layer_user_info)">
                        <SafetyCertificateOutlined style="font-size: 28px;"/>
                        <h5 v-if="layer_user_info.pre_tstatus == 6" style=" margin-top: 5px;">{{ $t('waitlist.checked_in') }}</h5>
                        <h5 v-else style=" margin-top: 5px;">{{ $t('waitlist.check_in') }}</h5>
                    </div>
                    <div class="custom_big_btn green_color1" @click="doShowSeatLayer(layer_user_info)">
                        <CoffeeOutlined style="font-size: 28px;"/>
                        <h5 style="margin-top: 5px;">{{ $t('waitlist.seat') }}</h5>
                    </div>
                </a-space>
            </div>
            <div class="user_waitlist_data">
                <h4 style="background: #f5f5f5;line-height: 30px;">{{ $t('waitlist.waitlist_detail') }}</h4>
                <div>
                    <a-button style="background-color: #f5f5f5;">{{ layer_user_info.wait_code }}</a-button>
                    <a-button style="background-color: #f5f5f5; margin-left: 10px;" :disabled="true">
                        <template #icon>
                            <AuditOutlined />
                        </template>
                        {{ $t('waitlist.table_assign') }}
                    </a-button>
                </div>
                <div style="margin-top: 10px;">
                    <a-input-group compact>
                        <a-input v-model:value="layer_user_info.note" style="width: calc(100% - 80px)" :placeholder="$t('waitlist.visit_note')">
                            <template #prefix>
                                <FormOutlined />
                            </template>
                        </a-input>
                        <a-button @click="doEditNote(layer_user_info)" type="primary">{{ $t('common.submit') }}</a-button>
                    </a-input-group>
                </div>
                <div style="margin-top: 10px;">
                    <a-button :disabled="true">+ {{ $t('common.option') }}</a-button>
                </div>
            </div>
            <div class="user_timeline">
                <h4 style="background: #f5f5f5;line-height: 30px;">{{ $t('waitlist.timeline') }}</h4>
                <a-timeline v-if="!is_loading_timeline" style="height: 170px; overflow: auto; padding-top: 5px;">
                    <a-timeline-item v-for="(item,index) in timeline_list" v-bind:key="item.title + index">{{ item.title }}</a-timeline-item>
                </a-timeline>
                <a-skeleton active v-if="is_loading_timeline" />
            </div>
            <div class="user_bottom_action">
                <a-space>
                    <div class="custom_big_btn" v-if="layer_user_info.missed == 0" @click="doNoShow(layer_user_info)" style="color: rgb(233, 60, 60); border-color: rgb(233, 60, 60);">
                        <EyeInvisibleOutlined style="font-size: 28px;" />
                        <h5 style="margin-top: 5px;">{{ $t('waitlist.no_show') }}</h5>
                    </div>
                    <div class="custom_big_btn" v-else style="color: rgb(233, 60, 60); border-color: rgb(233, 60, 60);" @click="doRestore(layer_user_info)">
                        <RedoOutlined style="font-size: 28px;"/>
                        <h5 style="margin-top: 5px;">{{ $t('waitlist.restore') }}</h5>
                    </div>
                    <a-popover title="" trigger="click" :open="show_arrive_layer">
                        <template #content>
                            <a-space>
                                <div class="custom_big_btn blue_color1" :class="{'current': layer_user_info.all_arrived == 0}" style="width: 120px;" @click="chooseArrive(0)">
                                    <MinusCircleOutlined  style="font-size: 28px;"/>
                                    <h5 style="margin-top: 5px;">{{ $t('waitlist.not_arrived') }}</h5>
                                </div>
                                <div class="custom_big_btn blue_color1" :class="{'current': layer_user_info.all_arrived == 2}" style="width: 120px;" @click="chooseArrive(2)">
                                    <UserOutlined style="font-size: 28px;" />
                                    <h5 style="margin-top: 5px;">{{ $t('waitlist.partial_arrived') }}</h5>
                                </div>
                                <div class="custom_big_btn blue_color1" :class="{'current': layer_user_info.all_arrived == 1}" @click="chooseArrive(1)">
                                    <TeamOutlined style="font-size: 28px;"/>
                                    <h5 style="margin-top: 5px;">{{ $t('waitlist.all_arrived') }}</h5>
                                </div>
                            </a-space>
                        </template>
                        <div class="custom_big_btn blue_color1" @click="doShowLayer('show_arrive_layer')">
                            <MinusCircleOutlined v-if="layer_user_info.all_arrived == 0" style="font-size: 28px;"/>
                            <UserOutlined v-if="layer_user_info.all_arrived == 2" style="font-size: 28px;"/>
                            <TeamOutlined v-if="layer_user_info.all_arrived == 1" style="font-size: 28px;"/>
                            <h5 style="margin-top: 5px;" v-if="layer_user_info.all_arrived == 0">{{ $t('waitlist.not_arrived') }}</h5>
                            <h5 style="margin-top: 5px;" v-if="layer_user_info.all_arrived == 2">{{ $t('waitlist.partial_arrived') }}</h5>
                            <h5 style="margin-top: 5px;" v-if="layer_user_info.all_arrived == 1">{{ $t('waitlist.all_arrived') }}</h5>
                        </div>
                    </a-popover>
                    <a-popover title="" trigger="click" placement="topRight" :open="show_color_tag_layer">
                        <template #content>
                            <div>
                                <a-flex wrap="wrap" gap="small" style="width: 320px;">
                                    <div class="custom_big_btn" @click="chooseColorTag()">
                                        <h5 style="margin-top: 20px;">{{ $t('common.default') }}</h5>
                                    </div>
                                    <div class="custom_big_btn" v-for="item in color_tag_list" v-bind:key="item.tag_id" @click="chooseColorTag(item)" :style="{backgroundColor: item.color}">
                                        <h5 style="margin-top: 20px;">{{ item.tname }}</h5>
                                    </div>
                                </a-flex>
                            </div>
                        </template>
                        <div class="custom_big_btn" @click="doShowLayer('show_color_tag_layer')">
                            <ClearOutlined style="font-size: 28px; color: #000;"/>
                            <h5 style="color: #000; margin-top: 5px;">{{ $t('common.color_tag') }}</h5>
                        </div>
                    </a-popover>
                </a-space>
            </div>
        </a-drawer>
        <a-drawer class="user_info_zone" :zIndex="1000" @close="doHideCallaheadInfoLayer" v-model:open="show_callahead_info_layer" placement="right" :width="!is_mobile ? 400 : 375" :footer="null">
            <div class="user_info_header">
                <a-row>
                    <a-col :span="8">
                        <div class="custom_big_btn yellow_color1" style="padding-top: 10px;">
                            <h4>{{ layer_user_info.preorder_time }}</h4>
                            <h4>{{ $t('waitlist.callahead') }}</h4>
                        </div>
                    </a-col>
                    <a-col :span="14">
                        <div>
                            <h4>{{ layer_user_info.people_num }} | {{ layer_user_info.customer_name }}</h4>
                            <p style="margin-bottom: 5px;">{{ layer_user_info.phone_num }}</p>
                            <span v-for="item in layer_user_info.guest_tags" v-bind:key="item.name">
                                <a-tag :color="item.color">{{ item.name }}</a-tag>
                            </span>
                        </div>
                    </a-col>
                    <a-col v-if="false">
                        <EditOutlined style="font-size: 24px; color: #000;"/>
                    </a-col>
                </a-row>
            </div>
            <a-divider />
            <div class="user_top_action">
                <div class="custom_big_btn" @click="doJoinCallahead(layer_user_info)">
                    <PlusCircleOutlined class="list_icon" style="font-size: 28px;"/>
                    <h5 style="color: #000; margin-top: 5px;">{{ $t('waitlist.join_waitlist') }}</h5>
                </div>
            </div>
            <div class="user_waitlist_data">
                <h4 style="background: #f5f5f5;line-height: 30px;">{{ $t('waitlist.callahead_detail') }}</h4>
                <div style="margin-top: 10px;">
                    <a-input-group compact>
                        <a-input v-model:value="layer_user_info.note" :disabled="true" style="width: calc(100% - 80px)" :placeholder="$t('waitlist.visit_note')">
                            <template #prefix>
                                <FormOutlined />
                            </template>
                        </a-input>
                        <a-button @click="doEditNote(layer_user_info)" type="primary">{{ $t('common.submit') }}</a-button>
                    </a-input-group>
                </div>
                <div style="margin-top: 10px;">
                    <a-button :disabled="true">+ {{ $t('common.option') }}</a-button>
                </div>
            </div>
            <div class="user_timeline">
                <h4 style="background: #f5f5f5;line-height: 30px;">{{ $t('waitlist.timeline') }}</h4>
                <a-timeline style="height: 170px; overflow: auto; padding-top: 5px;">
                    <a-timeline-item v-for="(item,index) in callahead_timeline_list" v-bind:key="item.title + index">{{ item.title }}</a-timeline-item>
                </a-timeline>
            </div>
            <div class="user_bottom_action">
                <a-space>
                    <div class="custom_big_btn" @click="doDelCallahead(layer_user_info)">
                        <RestOutlined class="list_icon" style="font-size: 28px;"/>
                        <h5 style="color: #000; margin-top: 5px;">{{ $t('common.delete') }}</h5>
                    </div>
                </a-space>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import {
        HistoryOutlined,
        UsergroupAddOutlined,
        GlobalOutlined,
        MailOutlined,
        CoffeeOutlined,
        DesktopOutlined,
        PhoneOutlined,
        CommentOutlined,
        SendOutlined,
        EditOutlined,
        SafetyCertificateOutlined,
        AuditOutlined,
        FormOutlined,
        EyeInvisibleOutlined,
        MinusCircleOutlined,
        ClearOutlined,
        UserOutlined,
        TeamOutlined,
        FieldTimeOutlined,
        MessageFilled,
        AlertFilled,
        DownOutlined,
        BorderlessTableOutlined,
        RedoOutlined,
        Loading3QuartersOutlined,
        LoadingOutlined,
        PlusCircleOutlined,
        RestOutlined,
    } from '@ant-design/icons-vue';

    import { mapActions, mapGetters } from 'vuex';
    import { Modal } from 'ant-design-vue';
    import moment from 'moment-timezone';

    export default {
        name: 'WaitlistView',
        components: {
            HistoryOutlined,
            UsergroupAddOutlined,
            GlobalOutlined,
            MailOutlined,
            CoffeeOutlined,
            DesktopOutlined,
            PhoneOutlined,
            CommentOutlined,
            SendOutlined,
            EditOutlined,
            SafetyCertificateOutlined,
            AuditOutlined,
            FormOutlined,
            EyeInvisibleOutlined,
            MinusCircleOutlined,
            ClearOutlined,
            UserOutlined,
            TeamOutlined,
            FieldTimeOutlined,
            MessageFilled,
            AlertFilled,
            DownOutlined,
            BorderlessTableOutlined,
            RedoOutlined,
            Loading3QuartersOutlined,
            LoadingOutlined,
            PlusCircleOutlined,
            RestOutlined,
        },
        data() {
            return {
                sid: this.$store.state.app.sid,
                sname: this.$store.state.app.sname,
                timezone: this.$store.state.app.timezone,
                is_mobile: this.$store.state.app.is_mobile,
                moment,
                now_type: 'waitlist',
                
                show_add_layer: false,
                show_notify_layer: false,
                show_seat_layer: false,
                show_info_layer: false,
                show_color_tag_layer: false,
                show_arrive_layer: false,
                show_guest_number_zone: true,
                show_callahead_info_layer: false,
                is_loading: false,
                is_adding: false,
                is_sending: false,
                is_loading_timeline: false,
                q: '',
                baseStyle: {
                    width: '20%',
                    height: '54px',
                },
                current: 1,
                total_count: 0,
                page_size: 20,
                current_party_type: 'all',
                party_type_list: [],            // 可缓存
                list: [],
                party_type_data_dict: {},
                layer_user_info: {},
                timeline_list: [],
                color_tag_list: [],             // 可缓存
                add_type: 'waitlist',
                callahead_list: [],
                callahead_timeline_list: [],

                value1: null,

                add_people_num: 0,
                add_customer_name: '',
                add_phone_code: '+65',
                add_phone_num: '',
                add_note: '',
                seat_table_name: '',
                seat_real_tid: '',
                add_join_time: '',

                list_timer: null,
                summery_timer: null,
                is_guest_number_hide: false,
                disabledDate: (current) => {
                    return current && current < moment.tz(this.timezone).subtract(1, 'days');
                },
            };
        },
        mounted() {
            this.get_party_type_list();
            this.get_color_tags();

            this.get_list();
            this.get_summary();

            clearInterval(this.list_timer);

            this.list_timer = setInterval(() => {
                this.get_list();
                this.get_summary();
            }, 10 * 1000);
        },
        watch: {
        },
        created() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        beforeUnmount() {
            clearInterval(this.list_timer);
        },
        computed: {
            ...mapGetters({
            })
        },
        methods: {
            ...mapActions({
                add_waitlist: 'waitlist/add_waitlist',
                do_get_request: 'waitlist/do_get_request'
            }),
            handleTypeChange(value) {
                this.now_type = value;
                this.add_type = value;

                if (this.now_type === 'callahead') {
                    clearInterval(this.list_timer);
                    this.list_timer = null;
                    this.get_callahead_list();
                } else {
                    if (this.list_timer == null) {
                        this.onSearch();

                        this.list_timer = setInterval(() => {
                            this.onSearch();
                        }, 10 * 1000);
                    }
                }
            },
            onSearch() {
                if (this.now_type === 'callahead') {
                    this.get_callahead_list();
                } else if (this.now_type === 'waitlist') {
                    this.get_list();
                    this.get_summary();
                }
            },
            async get_callahead_list() {
                this.is_loading = true;
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    api: '/weapp/admin/preorder/call_ahead_list'
                });

                this.callahead_list = res_data.data.wait_list.map(item => {
                    item.phone_num_short = item.phone_num ? '*' + item.phone_num.substr(-4) : '';
                    item.preorder_time = moment.tz(item.preorder_timestamp * 1, this.timezone).format('HH:mm A');

                    let options = [];
                    for (var o in item.options) {
                        var opvalue = item.options[o].split('@@');
                        var subvalue = '';

                        if (opvalue.length > 1) {
                            subvalue = opvalue[1].split('=')[1]
                            subvalue = '- ' + subvalue
                        }

                        options.push(o + ':' + opvalue[0] + subvalue);
                    }

                    item.options = options.join(' | ');

                    return item;
                });

                this.is_loading = false;
            },
            async get_list() {
                this.is_loading = true;
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tstatus: '0,1,6',
                    page: this.current,
                    q: this.q,
                    type: this.current_party_type,
                    api: '/weapp/admin/v4/preorder/list'
                });

                this.list = res_data.data.list.map(item => {
                    item.wait_from_now = this.custom_from_now(item.create_timestamp * 1);
                    item.ready_text_time = this.custom_from_now(item.table_ready_send_time * 1);
                    item.phone_num_short = item.phone_num ? '*' + item.phone_num.substr(-4) : '';

                    let options = [];
                    for (var o in item.options) {
                        var opvalue = item.options[o].split('@@');
                        var subvalue = '';

                        if (opvalue.length > 1) {
                            subvalue = opvalue[1].split('=')[1]
                            subvalue = '- ' + subvalue
                        }

                        options.push(o + ':' + opvalue[0] + subvalue);
                    }

                    item.options = options.join(' | ');

                    return item;
                });

                this.page_size = res_data.data.page_size;
                this.is_loading = false;
            },
            custom_from_now(timestamp) {
                let now_time = new Date().getTime();
                let diff = now_time - timestamp * 1;

                let hours = Math.floor(diff / (60 * 60 * 1000));
                let minutes = Math.floor((diff - hours * 60 * 60 * 1000) / (60 * 1000));

                minutes = minutes == 0 ? 1 : minutes;

                if (hours > 0) {
                    return hours + 'h ' + minutes + 'm';
                }

                return minutes + 'm';
            },
            async get_summary () {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tstatus: '0,1,6',
                    q: this.q,
                    type: this.current_party_type,
                    api: '/weapp/admin/v4/preorder/summary'
                });

                if (res_data.code == 100000) {
                    let { pager_info, summary } = res_data.data;
                    this.total_count = pager_info.total_count;

                    this.party_type_data_dict = summary;
                }
            },
            async get_party_type_list() {
                let res_data = await this.do_get_request({
                    sid: this.sid, api: '/weapp/admin/partytype/list'
                });

                let party_type_list = [{ name: this.$t('waitlist.all'), value: 'all' }];
                
                if (res_data.code == 100000) {
                    res_data.data.map(item => {
                        party_type_list.push({
                            name: item.type + '(' + item.range + ')',
                            value: item.type
                        });
                    });
                } else {
                    party_type_list = [
                        { name: this.$t('waitlist.all'), value: 'all' },
                        { name: "A(1-4)", value: 'A' },
                        { name: "B(5-6)", value: 'B' },
                        { name: "C(7-)", value: 'C' }
                    ];
                }

                party_type_list.push({ name: this.$t('waitlist.no_show'), value: 'missed' });

                this.party_type_list = party_type_list;
            },
            async get_timeline() {
                this.is_loading_timeline = true;
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    type: 'preorder',
                    id: this.layer_user_info.pre_tid,
                    api: '/weapp/admin/message/get'
                });

                let message_status = [this.$t('common.sending'), this.$t('common.send_success'), this.$t('common.send_error')];

                let data_list = [];
                if (res_data.code == 100000) {
                    let notification_list = res_data.data.list;

                    data_list.push({
                        title: moment.tz(this.layer_user_info.create_timestamp * 1, this.timezone).format('HH:mm A') + ' ' + this.$t('waitlist.join_waitlist'),
                        detail: '',
                        timestamp: this.layer_user_info.create_timestamp
                    });

                    notification_list.map(item => {
                        data_list.push({
                            title: moment.tz(item.create_timestamp * 1, this.timezone).format('HH:mm A') + ' ' + this.$t(`common.${item.type}`) + '(' + message_status[item.status] + ')',
                            detail: item.content,
                            timestamp: item.create_timestamp
                        });
                    });

                    if (this.layer_user_info.missed_timestamp) {
                        data_list.push({
                            title: moment.tz(this.layer_user_info.missed_timestamp * 1, this.timezone).format('HH:mm A') + ' ' + this.$t('waitlist.no_show'),
                            detail: '',
                            timestamp: this.layer_user_info.missed_timestamp
                        });
                    }

                    if (this.layer_user_info.checkedin_timestamp) {
                        data_list.push({
                            title: moment.tz(this.layer_user_info.checkedin_timestamp * 1, this.timezone).format('HH:mm A') + ' ' + this.$t('waitlist.checked_in'),
                            detail: '',
                            timestamp: this.layer_user_info.checkedin_timestamp
                        });
                    }

                    if (this.layer_user_info.recover_timestamp) {
                        data_list.push({
                            title: moment.tz(this.layer_user_info.recover_timestamp * 1, this.timezone).format('HH:mm A') + ' ' + this.$t('waitlist.restore'),
                            detail: '',
                            timestamp: this.layer_user_info.recover_timestamp
                        });
                    }

                    if (this.layer_user_info.arrived_time > 0) {
                        data_list.push({
                            title: moment.tz(this.layer_user_info.arrived_time * 1, this.timezone).format('HH:mm A') + ' ' + (this.layer_user_info.all_arrived == 1 ? this.$t('waitlist.all_arrived') : this.$t('waitlist.partial_arrived')),
                            detail: '',
                            timestamp: this.layer_user_info.arrived_time
                        });
                    }

                    data_list = data_list.sort((a, b) => a.timestamp - b.timestamp);

                    this.timeline_list = data_list;
                    this.is_loading_timeline = false;
                }
            },
            async get_guest_tag() {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    phone_num_list: this.layer_user_info.phone_num,
                    api: '/weapp/admin/guests/tag/get',
                    method: 'post'
                });

                if (res_data.code == 100000) {
                    let guest_info = res_data.data.length > 0 ? res_data.data[0] : {};
                    let new_tags_arr = [];
                    new_tags_arr.push({
                        name: (guest_info.wait_count + guest_info.reservation_count) + ' ' + this.$t('common.visits'),
                        color: '#87d068'
                    });

                    new_tags_arr.push({
                        name: guest_info.missed_count + ' ' + this.$t('waitlist.no_show'),
                        color: 'red'
                    });

                    guest_info.tags.map(item => {
                        new_tags_arr.push({
                            name: '!' + item.tname,
                            color: 'red'
                        });
                    });

                    this.layer_user_info.guest_tags = new_tags_arr;
                }
            },
            async get_color_tags() {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    status: 'active',
                    service: 'waitlist',
                    api: '/weapp/admin/colortags/list'
                });
                
                if (res_data.code == 100000) {
                    let tag_list = res_data.data;
                    this.color_tag_list = tag_list;
                }
            },
            async do_noshow(pre_tid) {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tid: pre_tid,
                    api: '/weapp/admin/preorder/missed'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));

                    this.onSearch();
                    this.doHideInfoLayer();
                }
            },
            async do_checkin(pre_tid) {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tid: pre_tid,
                    api: '/weapp/admin/preorder/wait_for_seat'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    
                    this.onSearch();
                    this.doHideInfoLayer();
                }
            },
            async do_seat() {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tname: this.seat_table_name,
                    real_tid: this.seat_real_tid,
                    pre_tid: this.layer_user_info.pre_tid,
                    api: '/weapp/admin/preorder/seat'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.show_seat_layer = false;
                    
                    this.onSearch();
                    this.doHideInfoLayer();
                }
            },
            async do_send_notification(type) {
                this.is_sending = true;
                if (type == 'two_way_message') {
                    await this.do_send_two_way_message();
                } else if (type == 'two_way_voice') {
                    await this.do_send_two_way_voice();
                } else if (type == 'resend_invite') {
                    await this.do_resend_invite();
                } else if (type == 'broadcast') {
                    await this.do_broadcast();
                }
                this.is_sending = false;
            },
            async do_send_two_way_message() {
                if (!this.layer_user_info.pre_tid) {
                    return;
                }
                
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tid: this.layer_user_info.pre_tid,
                    api: '/weapp/admin/preorder/table_ready/sms',
                    method: 'post'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.show_notify_layer = false;
                    this.doHideInfoLayer();
                }
            },
            async do_send_two_way_voice() {
                if (!this.layer_user_info.pre_tid) {
                    return;
                }
                
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tid: this.layer_user_info.pre_tid,
                    type: 'two-way',
                    api: '/weapp/admin/preorder/table_ready/phone',
                    method: 'post'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.show_notify_layer = false;
                    this.doHideInfoLayer();
                }
            },
            async do_resend_invite() {
                if (!this.layer_user_info.pre_tid) {
                    return;
                }
                
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tid: this.layer_user_info.pre_tid,
                    api: '/weapp/admin/preorder/resend'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.show_notify_layer = false;
                    this.doHideInfoLayer();
                }
            },
            async do_broadcast() {
                if (!this.layer_user_info.pre_tid) {
                    return;
                }

                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tid: this.layer_user_info.pre_tid,
                    api: '/weapp/admin/preorder/table_ready/set',
                    method: 'post'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.show_notify_layer = false;
                    this.doHideInfoLayer();
                }
            },
            async doSubmitJoinWaitlist() {
                if (!this.add_customer_name) {
                    this.$message.warning(this.$t('waitlist.input_name_tip'));
                    return;
                }

                if (!this.add_people_num || this.add_people_num == 0) {
                    this.$message.warning(this.$t('waitlist.choose_people_num_tip'));
                    return;
                }

                if (this.add_type == 'callahead' && this.add_join_time == '') {
                    this.$message.warning(this.$t('waitlist.choose_join_time_tip'));
                    return;
                }

                this.is_adding = true;

                if (this.add_type == 'waitlist') {
                    let post_data = {
                        sid: this.sid,
                        people_num: this.add_people_num,
                        customer_name: encodeURIComponent(this.add_customer_name),
                        phone_num: this.add_phone_num ? encodeURIComponent(this.add_phone_code + ' ' + this.add_phone_num) : '',
                        note: this.add_note,
                        sname: encodeURIComponent(this.sname),
                        wait_time: '',
                        options: '',
                        type: 'walk-in'
                    };
                    let res_data = await this.add_waitlist(post_data);
                    if (res_data.code == 100000) {
                        this.add_customer_name = '';
                        this.add_people_num = '';
                        this.add_phone_num = '';
                        this.add_note = '';
                        this.show_add_layer = false;

                        this.current = 1;
                        this.current_party_type = 'all';
                        this.onSearch();
                        this.show_guest_number_zone = true;
                    }
                } else if (this.add_type == 'callahead') {
                    let now_time = moment(this.timezone).format('YYYY/MM/DD HH:mm');

                    if (this.add_join_time <= now_time) {
                        this.$message.warning(this.$t('waitlist.choose_right_join_time_tip'));
                        this.is_adding = false;
                        return;
                    }

                    let res_data = await this.do_get_request({
                        sid: this.sid,
                        people_num: this.add_people_num,
                        customer_name: this.add_customer_name,
                        phone_num: this.add_phone_num ? this.add_phone_code + ' ' + this.add_phone_num : '',
                        note: this.add_note,
                        sname: this.sname,
                        ahead_minutes: 0,
                        join_time: this.add_join_time,
                        api: '/weapp/admin/preorder/call_ahead',
                        method: 'post'
                    });
                    
                    if (res_data.code == 100000) {
                        this.add_customer_name = '';
                        this.add_people_num = '';
                        this.add_phone_num = '';
                        this.add_note = '';
                        this.add_join_time = '';

                        this.show_guest_number_zone = true;
                        this.show_add_layer = false;

                        this.get_callahead_list();
                    }
                }

                this.is_adding = false;
            },
            async chooseColorTag(color_item) {
                this.show_color_tag_layer = false;

                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tid: this.layer_user_info.pre_tid,
                    color_tag_list: color_item ? [color_item] : [],
                    api: '/weapp/admin/preorder/update_color_tag',
                    method: 'post'
                });

                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.layer_user_info.color_tags = [color_item];
                }

                this.show_arrive_layer = false;
            },
            async chooseArrive(arrive) {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tid: this.layer_user_info.pre_tid,
                    all_arrived: arrive,
                    api: '/weapp/admin/preorder/all_arrived/set',
                    method: 'post'
                });

                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.layer_user_info.all_arrived = arrive;
                }

                this.show_arrive_layer = false;
            },
            onClose() {
                this.show_add_layer = false;
                this.show_guest_number_zone = true;
            },
            choosePeopleNum(num) {
                this.add_people_num = num;
                this.show_guest_number_zone = false;
            },
            doShowNotifyLayer(item) {
                this.show_notify_layer = true;

                if (item) {
                    this.layer_user_info = item;
                }
            },
            doShowSeatLayer(item) {
                this.show_seat_layer = true;

                if (item) {
                    this.layer_user_info = item;
                }
            },
            doShowInfoLayer(item) {
                this.show_info_layer = true;
                this.layer_user_info = item;

                this.get_timeline();
                this.get_guest_tag();
            },
            doShowLayer(layer) {
                this[layer] = this[layer] ? false : true;
            },
            doHideInfoLayer() {
                this.show_arrive_layer = false;
                this.show_info_layer = false;
                this.show_color_tag_layer = false;
                this.layer_user_info = {};
                this.show_notify_layer = false;
            },
            doCheckIn(item) {
                if (item.pre_tstatus == 6) {
                    return;
                }

                let that = this;
                Modal.confirm({
                    title: this.$t('waitlist.check_in') + `${item.customer_name}(${item.people_num})?`,
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    maskClosable: true,
                    onOk() {
                        that.do_checkin(item.pre_tid);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            doNoShow(item) {
                let that = this;
                Modal.confirm({
                    title: this.$t('waitlist.no_show') + ` ${item.customer_name}(${item.people_num})?`,
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    maskClosable: true,
                    onOk() {
                        that.do_noshow(item.pre_tid);
                    },
                    onCancel() {},
                });
            },
            choosePartyTypeTab(type) {
                this.current_party_type = type;
                this.current = 1;
                this.onSearch();
            },
            async doEditNote(item) {
                if (!item.pre_tid) {
                    return;
                }

                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tid: item.pre_tid,
                    note: encodeURIComponent(item.note),
                    api: '/weapp/admin/preorder/update_note',
                    method: 'get'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.doHideInfoLayer();
                }
            },
            showGuestNumberZone() {
                this.show_guest_number_zone = true;
            },
            doRestore(item) {
                let that = this;
                Modal.confirm({
                    title: this.$t('waitlist.restore') + ` ${item.customer_name}(${item.people_num})?`,
                    content: this.$t('waitlist.restore_tip'),
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    maskClosable: true,
                    onOk() {
                        that.do_restore(item.pre_tid);
                    },
                    onCancel() {},
                });
            },
            async do_restore(pre_tid) {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    pre_tid: pre_tid,
                    api: '/weapp/admin/preorder/back_to_waiting',
                    method: 'post'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));

                    this.current_party_type = 'all';
                    this.current = 1;
                    this.q = '';

                    this.onSearch();
                }
            },
            onDateChange(val) {
                this.add_join_time = val.format('YYYY/MM/DD HH:mm');
            },
            doShowCallaheadInfoLayer(item) {
                this.show_callahead_info_layer = true;
                this.layer_user_info = item;

                this.callahead_timeline_list = [
                    {
                        title: moment.tz(this.layer_user_info.create_timestamp * 1, this.timezone).format('HH:mm A') + ' ' + this.$t('waitlist.created_callahead'),
                        detail: '',
                        timestamp: this.layer_user_info.create_timestamp
                    }
                ];

                this.get_guest_tag();
            },
            doJoinCallahead(item) {
                let that = this;

                Modal.confirm({
                    title: this.$t('waitlist.join_waitlist_tip'),
                    content: '',
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    maskClosable: true,
                    onOk() {
                        that.do_join_callahead(item.id);
                    },
                    onCancel() {},
                });
            },
            doDelCallahead(item) {
                let that = this;

                Modal.confirm({
                    title: this.$t('common.delete_tip'),
                    content: '',
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    maskClosable: true,
                    onOk() {
                        that.do_delete_callahead(item.id);
                    },
                    onCancel() {},
                });
            },
            async do_delete_callahead(id) {
                if (!id) {
                    return;
                }

                let res_data = await this.do_get_request({
                    sid: this.sid,
                    id: id,
                    api: '/weapp/admin/preorder/call_ahead/del',
                    method: 'post'
                });
                
                if (res_data.code == 100000) {
                    this.doHideCallaheadInfoLayer();
                    this.$message.success(this.$t('common.success'));
                    this.get_callahead_list();
                }
            },
            async do_join_callahead(id) {
                if (!id) {
                    return;
                }
            
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    id: id,
                    api: '/weapp/admin/preorder/call_ahead/to_waitlist',
                    method: 'post'
                });
                
                if (res_data.code == 100000) {
                    this.doHideCallaheadInfoLayer();
                    this.$message.success(this.$t('common.success'));
                    this.get_callahead_list();
                }
            },
            doHideCallaheadInfoLayer() {
                this.show_callahead_info_layer = false;
                this.layer_user_info = {};
            },
        }
    }
</script>

<style scoped>
    .party_type_zone {
        overflow-x: auto;
    }
    .paty_type_item {
        padding: 6px;
        border: 1px solid #c9d0d9bf;
        border-radius: 10px;
        text-align: center;
        min-width: 100px;
    }

    .paty_type_item.current {
        background: #fff;
    }

    .guest_num_item {
        display: inline-block;
        border: 1px solid rgb(0, 0, 0);
        width: 70px;
        height: 70px;
        text-align: center;
        vertical-align: middle;
        line-height: 70px;
        font-size: 32px;
    }
    .guest_num_item span {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    .guest_num_item span.active {
        background-color: #27cf55;
    }
    .custom_big_btn {
        display: inline-block;
        height: 75px;
        width: 100px;
        border-radius: 5px;
        border: 1px solid #d7cdcd;
        text-align: center;
        padding: 5px;
        cursor: pointer;
        background-color: #fff;
    }
    .custom_big_btn .list_icon {
        font-size: 38px;
    }
    .user_info_zone .ant-drawer-body{
        overflow: hidden;
        padding: 10px 24px;
    }
    .user_info_zone .ant-divider {
        margin: 0 0 10px;
    }
    .user_waitlist_data, .user_timeline {
        margin-top: 10px;
    }
    .user_info_header {
        max-height: 100px;
        overflow: auto;
    }
    .user_info_zone .user_top_action .custom_big_btn {
        width: 115px;
        height: 60px;
    }
    .user_info_zone .user_bottom_action .custom_big_btn {
        width: 115px;
        height: 60px;
    }
    .user_timeline .ant-timeline .ant-timeline-item {
        padding-bottom: 7px;
    }
    .yellow_color1{
        color: #fff;
        background-color: #f9bd58;
        border-color: #f9bd58;
    }

    .yellow_color2{
        color: #fff; background-color: #e78156; border-color: #e78156;
    }

    .green_color1{
        color: #fff; background-color: #5f9562; border-color: #5f9562;
    }

    .blue_color1{
        color: rgb(48, 48, 240); border-color: rgb(48, 48, 240);
    }
    .blue_color1.current {
        background-color: rgb(48, 48, 240);
        width: 120px; color: #fff;
    }

    @media screen and (max-width: 768px) {
        .ant-divider {
            margin: .5rem 0;
        }
        .ant-list-item {
            padding: .3rem .5rem;
        }
        .ant-list-item .custom_big_btn {
            width: 50px;
            padding: 10px 2px 2px;
            font-size: 12px;
        }

        .ant-list-item .custom_big_btn .list_icon {
            font-size: 24px;
        }
        .ant-list-item .custom_big_btn h5 {
            white-space: nowrap;
            overflow: hidden;
        }
        .user_info_zone .ant-drawer-body {
            padding: .3rem .5rem;
        }
        
        .party_type_zone {
            font-size: .8rem;
        }
    }
</style>
