import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import { message } from 'ant-design-vue';
import _ from "lodash";
import i18n from './language';
import './registerServiceWorker'

const app = createApp(App);

app.config.globalProperties.$message = message;
app.config.globalProperties._ = _;

app.use(Antd).use(i18n).use(store).use(router).mount('#app');
