<template>
    <div id="components-layout-demo-side" style="height: 100vh">
        <router-view v-if="is_login" />
        <a-layout v-else style="height: 100vh">
            <a-layout-sider v-model:collapsed="collapsed" collapsible>
                <div class="logo">
                    <h4 v-if="!collapsed" style="color: #fff; width: 100%; overflow: hidden; line-height: 40px; text-align: center;">{{ sname }}</h4>
                    <img v-else :src="slogo" style="width: 50px; height: auto; margin: 0 auto;"/>
                </div>
                <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
                    <a-menu-item key="1" @click="openPage('waitlist')">
                        <ProfileOutlined />
                        <span>{{ $t('waitlist.waitlist') }}</span>
                    </a-menu-item>
                    <a-menu-item key="2" @click="openPage('reserve')">
                        <CalendarOutlined />
                        <span>{{ $t('reserve.reserve') }}</span>
                    </a-menu-item>
                    <a-menu-item key="3" :disabled="true" v-if="false">
                        <AppstoreOutlined />
                        <span>{{ $t('floorplan.floorplan') }}</span>
                    </a-menu-item>
                    <a-sub-menu key="sub1" v-if="false">
                        <template #title>
                            <span>
                                <span>User</span>
                            </span>
                        </template>
                        <a-menu-item key="3">Tom</a-menu-item>
                        <a-menu-item key="4">Bill</a-menu-item>
                        <a-menu-item key="5">Alex</a-menu-item>
                    </a-sub-menu>
                    <a-divider />
                    <a-menu-item key="5" @click="openPage('setting')">
                        <SettingOutlined />
                        <span>{{ $t('setting.setting')}}</span>
                    </a-menu-item>
                </a-menu>
                <h5 v-if="!collapsed" style="bottom: 50px; left: 50px; text-align: center; color: #fff;color: rgb(255, 255, 255);position: fixed;">{{ $t('setting.version_label') }} {{ version + ' (' + build_number + ')' }}</h5>
                <h5 v-else style="bottom: 50px; left: 25px; text-align: center; color: #fff;color: rgb(255, 255, 255);position: fixed;">{{ version }}</h5>
            </a-layout-sider>
            <a-layout>
                <a-layout-content>
                    <router-view/>
                    <a-flex class="footer_nav">
                        <div class="footer_nav_item" @click="openPage('waitlist')" :class="{current: now_path == '/waitlist'}">
                            <span><ProfileOutlined /></span>
                            <span style="padding-top: 5px;">{{ $t('waitlist.waitlist') }}</span>
                        </div>
                        <div class="footer_nav_item" @click="openPage('reserve')" style="border-left: none; border-right: none;" :class="{current: now_path == '/reserve'}">
                            <span><CalendarOutlined /></span>
                            <span style="padding-top: 5px;">{{ $t('reserve.reserve') }}</span>
                        </div>
                        <div class="footer_nav_item" @click="openPage('setting')" :class="{current: now_path == '/setting'}">
                            <span><SettingOutlined /></span>
                            <span style="padding-top: 5px;">{{ $t('setting.setting')}}</span>
                        </div>
                    </a-flex>
                </a-layout-content>
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
    // import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    import {
        ProfileOutlined,
        CalendarOutlined,
        AppstoreOutlined,
        SettingOutlined,
    } from '@ant-design/icons-vue';
    
    export default {
        name: 'AppPage',
        components: {
            ProfileOutlined,
            CalendarOutlined,
            AppstoreOutlined,
            SettingOutlined,
        },
        data() {
            return {
                is_login: this.$router.currentRoute.value.fullPath.indexOf('/login') > -1 ? true : false,
                collapsible: false,
                selectedKeys: [],
                collapsed: false,
                sid: this.$store.state.app.sid,
                token: this.$store.state.app.token,
                version: this.$store.state.app.version,
                build_number: this.$store.state.app.build_number,
                is_mobile: this.$store.state.app.is_mobile,
                now_path: this.$router.currentRoute.value.fullPath,
            };
        },
        beforeMount() {
            // console.log(this.$router.currentRoute.value.fullPath);
            this.is_login = this.$router.currentRoute.value.fullPath.indexOf('/login') > -1 ? true : false;
        },
        updated() {},
        beforeCreate() {},
        created() {
        },
        watch: {
            '$route'() {
                this.is_login = this.$router.currentRoute.value.fullPath.indexOf('/login') > -1 ? true : false;

                let now_path = this.$router.currentRoute.value.fullPath;
                this.now_path = now_path;

                if (now_path.indexOf('/waitlist') > -1) {
                    this.selectedKeys = ['1'];
                } else if (now_path.indexOf('/reserve') > -1) {
                    this.selectedKeys = ['2'];
                } else if (now_path.indexOf('/setting') > -1) {
                    this.selectedKeys = ['5'];
                }
            }
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
                sname: state => state.app.sname,
                slogo: state => state.app.slogo,
            }),
        },
        methods: {
            openPage(type) {
                if (type === 'waitlist') {
                    this.$router.push({
                        path: '/waitlist'
                    });
                } else if (type === 'reserve') {
                    this.$router.push({
                        path: '/reserve'
                    });
                } else if (type === 'setting') {
                    this.$router.push({
                        path: '/setting'
                    });
                }
            },
            ...mapActions({
            }),
            ...mapMutations({
            })
        }
    };

</script>

<style scoped>
    #components-layout-demo-side .logo {
        height: 40px;
        margin: 16px;
        /* background: rgba(255, 255, 255, 0.3); */
    }

    .site-layout .site-layout-background {
        background: #fff;
    }
    [data-theme='dark'] .site-layout .site-layout-background {
        background: #141414;
    }
    .ant-layout-content {
        margin: 16px;
    }
    .footer_nav {
        display: none;
    }

    @media screen and (max-width: 768px) {
        .ant-layout-sider {
            display: none;
        }

        .ant-layout-content {
            margin: 5px;
        }

        .footer_nav {
            display: block;
            position: fixed;
            bottom: 0;
            width: 102%;
            left: -7px;
        }

        .footer_nav .footer_nav_item {
            width: 33.33%;
            display: inline-block;
            text-align: center;
            height: 55px;
            font-size: 16px;
            color: #666;
            background-color: #fff;
            padding-top: 7px;
        }

        .footer_nav .footer_nav_item span {
            display: block;
        }

        .footer_nav .footer_nav_item.current {
            color: #1677ff;
        }
    }
</style>
