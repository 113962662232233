import axiosApi from '../../kits/axiosApi';
import { message } from 'ant-design-vue';

let admin_host = 'http://127.0.0.1:5555';
let client_host = window.location.host;

if (client_host.indexOf('localhost') >= 0 || client_host.indexOf('127.0.0.1') >= 0 || client_host.indexOf('.link') >= 0) {
    admin_host = 'https://admin.minitable.link';
}

const state = {};
const getters = {};

const do_proxy_aj = async function(host, params) {
    params.url = encodeURIComponent(params.url);
    return new Promise((resolve, reject) => {
        axiosApi
            .post(host + '/weapp/admin/proxy', params)
            .then(res => {
                console.log(res.data.data);
                if (res.data.data.code != 100000) {
                    message.error(res.data.data.msg);
                    return;
                } else if (res.data.data.code == 100020 || res.data.data.code == 100002) {
                    message.error(res.data.data.msg);
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('sid');

                    window.location.replace('/login');
                    return;
                }

                if (res.data.data.data.data.code != 100000) {
                    message.error(res.data.data.data.data.msg);
                    return;
                } else if (res.data.data.data.data.code == 100020 || res.data.data.data.data.code == 100002) {
                    message.error(res.data.data.data.data.msg);
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('sid');

                    window.location.replace('/login');
                    return;
                }

                let res_data = res.data.data.data;
                if (res_data.data && res_data.data.data) {
                    resolve(res_data.data);
                } else {
                    resolve(res_data);
                }
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });
};

const gen_params = function (params) {
    let params_str = '';
    let arr = [];

    for (let key in params) {
        arr.push(key + '=' + params[key]);
    }

    params_str = arr.join('&');

    return params_str;
};

const actions = {
    async add_waitlist({ rootState }, params) {
        let aj_host = rootState.debug ? 'https://admin.minitable.link' : '';

        return await do_proxy_aj(aj_host,  {
            url: admin_host + '/weapp/admin/preorder/add?' + gen_params(params),
            param: params,
            method: 'get'
        });
    },
    async do_get_request({ rootState }, params) {
        let aj_host = rootState.debug ? 'https://admin.minitable.link' : '';
        let api = params.api;
        let method = params.method || 'get';

        delete params.api;
        delete params.method;

        return await do_proxy_aj(aj_host,  {
            url: admin_host + api + '?' + (method == 'get' ? gen_params(params) : ''),
            param: params,
            method: method || 'get'
        });
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}