import { createRouter, createWebHistory } from 'vue-router';

import Login from '../views/Login.vue';
import Waitlist from '../views/Waitlist.vue';
import Reserve from '../views/Reserve.vue';
import Setting from '../views/Setting.vue';

const routes = [
    { path: '/waitlist', name: 'waitlist', component: Waitlist },
    { path: '/reserve', name: 'reserve', component: Reserve },
    { path: '/login', name: 'login', component: Login },
    { path: '/setting', name: 'setting', component: Setting },
    { path: '/', redirect: '/login' },
    // { path: '/reserve', name: 'reserve', component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
