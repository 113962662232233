import { createStore, createLogger } from 'vuex';
import app from './modules/app';
import waitlist from './modules/waitlist';
import login from './modules/login';

const state = {
    debug:
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === 'localhost'
};

export default createStore({
    modules: {
        app,
        waitlist,
        login
    },
    state,
    strict: state.debug,
    plugins: state.debug ? [createLogger()] : []
})