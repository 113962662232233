import axiosApi from '../../kits/axiosApi';
import { message } from 'ant-design-vue';

const state = {};
const getters = {};

const actions = {
    do_login_aj({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5551' : '';

        return axiosApi
            .get(aj_host + '/weapp/admin/user/login', {
                params: {
                    user_id: post_data.user_id,
                    pswd: post_data.pswd
                }
            })
            .then((res) => {
                if (res.data.data.code == 100000) {
                    window.localStorage.setItem('token', res.data.data.data.token);
                    window.localStorage.setItem('user_name', post_data.user_id);
                    window.localStorage.setItem('role', res.data.data.data.role);
                    window.localStorage.setItem('sid', res.data.data.data.sid);
                    window.localStorage.setItem('slogo', res.data.data.data.slogo);
                    window.localStorage.setItem('sname', res.data.data.data.sname);
                    window.localStorage.setItem('timezone', res.data.data.data.timezone);
                    window.localStorage.setItem('country', res.data.data.data.country);

                    post_data.router.replace({ path: '/waitlist' });

                    commit('app/APP_SET_GLOBAL_DAT', res.data.data, { root: true });
                } else {
                    message.error(res.data.data.msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
